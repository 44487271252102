import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider, QueryCache } from 'react-query';
import { ThemeProvider } from '@mui/material/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import App from './app';
import Theme from './theme';
import './locales/i18n';

import '../scss/app.scss';

// Sentry.init({
//   dsn: 'https://6eab184f53b14a659fb953507dcbbee3@o224983.ingest.sentry.io/6204298',
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

const Index = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        onError: (error) => {
          toast(`🦄 ${error.detail}`, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          console.log('ERROR !', error);
        },
      },
    },
    queryCache: new QueryCache({
      onError: (error, query) => {
        // 🎉 only show error toasts if we already have data in the cache
        // which indicates a failed background update
        if (query.state.data !== undefined) {
          console.log('ERROR !!!!!!!');
        }
      },
    }),
  });

  return (
    <HelmetProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={Theme}>
          <QueryClientProvider client={queryClient}>
            <CssBaseline />
            <App />
          </QueryClientProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </HelmetProvider>
  );
};

ReactDOM.render(<Index />, document.getElementById('app'));
