import React, { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import LoadingScreen from '../components/loading-screen';
import MainLayout from '../layouts/main';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const HomePage = Loadable(lazy(() => import('../pages/home')));
const WorkDetail = Loadable(lazy(() => import('../pages/work-detail')));

const Router = () => {
  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <HomePage /> },
        { path: 'works/:slug', element: <WorkDetail /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
};

export default Router;
