import React, { useRef, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { alpha } from '@mui/material/styles';
import { Stack, Button, Divider, styled } from '@mui/material';

import useLocales from '../../hooks/useLocales';

const ButtonStyles = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  py: 1,
  px: 2.5,
  color: selected ? theme.palette.text.primary : theme.palette.text.disabled,
  textTransform: 'uppercase',
  [theme.breakpoints.down('md')]: {
    fontSize: 12,
    px: 1,
  },
}));

const LanguageSelector = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { allLang, currentLang, onChangeLang } = useLocales();
  console.log(allLang);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLang = (value) => {
    onChangeLang(value);
    handleClose();
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        divider={<Divider orientation="vertical" flexItem />}
      >
        {allLang.map((option) => (
          <ButtonStyles
            variant="text"
            selected={option.value === currentLang.value}
            onClick={() => handleChangeLang(option.value)}
            key={option.value}
          >
            {option.value}
          </ButtonStyles>
        ))}
      </Stack>
    </>
  );
};

export default LanguageSelector;
