import React from 'react';
import { Outlet } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { styled } from '@mui/system';

import Navigation from '../components/navigation';
import Footer from './footer';

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  // height: '100%',
  // // minHeight: '100%',
  padding: 0,
  margin: 0,
  paddingTop: 100,
  // overflowX: 'hidden',
}));

const MainLayout = () => {
  return (
    <RootStyle>
      {/* <Navigation /> */}
      <AnimatePresence
        exitBeforeEnter
        onExitComplete={() => console.log('exit complete')}
        style={{ height: '100%' }}
      >
        <Outlet />
      </AnimatePresence>
      <Footer />
    </RootStyle>
  );
};

export default MainLayout;
