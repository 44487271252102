import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// font-family: proxima-nova, sans-serif;
// font-weight: 700;
// font-style: normal;

// Proxima Nova Regular

// font-family: proxima-nova, sans-serif;
// font-weight: 400;
// font-style: normal;

// Proxima Nova Light

// font-family: proxima-nova, sans-serif;
// font-weight: 300;
// font-style: normal;

const CustomTheme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
      dark: '#282F65',
      contrastText: '#000000',
    },
    secondary: {
      main: '#000000',
      light: '#1c1c1c',
      contrastText: '#ffffff',
    },
    background: {
      dark: '#000000',
      light: 'rgba(255,255,255, 0.3)',
    },
  },
  mixins: {
    toolbar: {
      minHeight: 100,
    },
  },
  typography: {
    fontFamily:
      '"proxima-nova", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
    h1: {
      fontFamily:
        '"proxima-nova", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
    },
  },
  components: {
    // MuiCssBaseline: {
    //   styleOverrides: `
    //     @font-face {
    //       font-family: 'Helvetica Neue Bold';
    //       font-style: normal;
    //       font-display: swap;
    //       font-weight: 400;
    //       src: local('Helvetica Neue Bold'), url(${HelveticaNeueBoldWoff2}) format('woff2');
    //       unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    //     }
    //     '@font-face': {
    //         fontFamily: 'Helvetica Neue Light',
    //         fontStyle: 'normal',
    //         fontDisplay: 'swap',
    //         fontWeight: 400,
    //         src: local('Helvetica Neue Light'), url(${HelveticaNeueLightWoff2}) format('woff2'), url(${HelveticaNeueLightWoff}) format('woff'),
    //         unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    //       },
    //   `,
    // },

    // components: {
    //   MuiCssBaseline: {
    //     '@global': {
    //       '@font-face': {
    //         fontFamily: 'Helvetica Neue Light',
    //         fontStyle: 'normal',
    //         fontDisplay: 'swap',
    //         fontWeight: 400,
    //         src: `local('Helvetica Neue Light'), url(${HelveticaNeueLightWoff2}) format('woff2'), url(${HelveticaNeueLightWoff}) format('woff')`,
    //         unicodeRange: `U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;`
    //       },
    //       '@font-face': {
    //         fontDamily: 'Helvetica Neue Regular',
    //         fontStyle: 'normal',
    //         fontDisplay: 'swap',
    //         fontWeight: 400,
    //         src: `local('Helvetica Neue Regular'), local('Helvetica Neue'), url(${HelveticaNeueRegularWoff2}) format('woff2'), url(${HelveticaNeueRegularWoff}) format('woff')`,
    //         // unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    //       },
    //       '@font-face': {
    //         fontDamily: 'Helvetica Neue Bold',
    //         fontStyle: 'normal',
    //         fontDisplay: 'swap',
    //         fontWeight: 700,
    //         src: `local('Helvetica Neue Bold'), url(${HelveticaNeueBoldWoff2}) format('woff2'), url(${HelveticaNeueBoldWoff}) format('woff')`,
    //         // unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    //       },
    //     },
    //   },
    MuiAppBar: {
      styleOverrides: {
        root: {
          // borderBottomWidth: 1,
          // borderColor: '#AAAAAA',
          // borderStyle: 'solid',
          backgroundColor: 'rgba(255,255,255,0.8)',
          boxShadow: 'none',
          backdropFilter: 'blur(10px)',
          // borderBottom: '1px solid #AAAAAA',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          // fontFamily: 'Helvetica Neue Bold',
          textTransform: 'none',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        // disableRipple: true, // No more ripple!
      },
    },
  },
});

const Theme = responsiveFontSizes(CustomTheme);

export default Theme;
