import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Grid,
  Link,
  Divider,
  Container,
  Typography,
  IconButton,
  Stack,
  styled,
  useTheme,
} from '@mui/material';

import SKKLogo from '../../assets/images/skk-logo.png';

const LINKS = [
  {
    headline: 'Legal',
    children: [
      { name: 'Terms of Service', href: 'http://www.skk-soest.de/agb/' },
      { name: 'Privacy Policy', href: 'http://www.skk-soest.de/Datenschutz/' },
      { name: 'Legal Notice', href: 'http://www.skk-soest.de/impressum/' },
    ],
  },
  {
    headline: 'Contact',
    children: [
      {
        name: 'info@skk-soest.de',
        href: 'mailto:info@skk-soest.de',
        type: 'link',
      },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  color: theme.palette.secondary.contrastText,
  backgroundColor: theme.palette.secondary.light,
  marginTop: theme.spacing(16),
}));

const LogoStyle = styled('img')(({ theme }) => ({
  width: 130,
  mx: { xs: 'auto', md: 'inherit' },
}));

export default function Footer() {
  const theme = useTheme();
  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="xl" sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={8} md={3} sx={{ mb: 3 }}>
            <a href="http://skk-soest.de">
              <LogoStyle src={SKKLogo} />
            </a>
          </Grid>

          <Grid item xs={8} md={3} color="inherit">
            <Typography
              variant="body2"
              sx={{
                fontWeight: 700,
                pr: { md: 5 },
              }}
            >
              Repetitive Forms (Sometimes Singular)
            </Typography>
            <Typography
              variant="body2"
              sx={{
                pr: { md: 5 },
              }}
            >
              5.06.2022 – 18.09.2022
              <br />
              <br />
              RAUM SCHROTH
              <br /> im Museum Wilhelm Morgner <br />
              Thomästraße 1 <br />
              D-59494 Soest
            </Typography>
          </Grid>

          {LINKS.map((list) => {
            const { headline, children } = list;
            return (
              <Grid item xs={12} md={3} key={headline}>
                <Typography
                  component="p"
                  variant="overline"
                  sx={{ fontWeight: 700 }}
                >
                  {headline}
                </Typography>

                {children.map((link) => (
                  <>
                    {link.type === 'link' && (
                      <Link
                        href={link.href}
                        key={link.href}
                        color="inherit"
                        variant="body2"
                        sx={{ display: 'block' }}
                      >
                        {link.name}
                      </Link>
                    )}
                    {link.type !== 'link' && (
                      <Link
                        to={link.href}
                        key={link.href}
                        color="inherit"
                        variant="body2"
                        component={RouterLink}
                        sx={{ display: 'block' }}
                      >
                        {link.name}
                      </Link>
                    )}
                  </>
                ))}
              </Grid>
            );
          })}
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          Copyright © {new Date().getFullYear()} SKK Soest
        </Typography>
      </Container>
    </RootStyle>
  );
}
