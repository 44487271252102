import React from 'react';
import { motion } from 'framer-motion';

import { styled } from '@mui/system';
import { Box } from '@mui/material';

const RootStyle = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 999999,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#ffffff',
}));

const LogoStyles = styled('div')(({ theme }) => ({
  width: 60,
  [theme.breakpoints.up('md')]: {
    width: 100,
  },
}));

const LoadingScreen = ({ ...other }) => {
  return (
    <RootStyle {...other}>
      <motion.div
        initial={{ rotateZ: 0 }}
        animate={{ rotateZ: 360 }}
        transition={{
          duration: 2,
          ease: 'easeInOut',
          repeatDelay: 1,
          repeat: Infinity,
        }}
      >
        <LogoStyles>SKK SOEST</LogoStyles>
      </motion.div>
    </RootStyle>
  );
};

export default LoadingScreen;
