import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer, toast } from 'react-toastify';

import DSGVO from './components/dsgvo';

import Router from './routes';

const App = () => {
  return (
    <BrowserRouter>
      <Router />
      {/* <DSGVO /> */}
      <ToastContainer />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </BrowserRouter>
  );
};

App.propTypes = {};

export default App;
