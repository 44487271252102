import React from 'react';
import PropTypes from 'prop-types';
import CookieConsent from 'react-cookie-consent';
import { Button, Box, useTheme, Typography } from '@mui/material';

const MyButton = ({ children, onClick }) => {
  return (
    <Button
      variant="contained"
      size="large"
      sx={{ margin: 2 }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

const DSGVO = () => {
  const theme = useTheme();
  return (
    <CookieConsent
      location="bottom"
      buttonText="Verstanden"
      style={{
        background: theme.palette.primary.dark,
      }}
      overlay
      ButtonComponent={MyButton}
    >
      <Typography
        variant="h6"
        component="div"
        sx={{
          fontFamily: 'Helvetica Neue Bold',
          color: theme.palette.primary.contrastText,
        }}
      >
        This website uses cookies to enhance the user experience.
      </Typography>
    </CookieConsent>
  );
};

DSGVO.propTypes = {};

DSGVO.defaultProps = {};

export default DSGVO;
